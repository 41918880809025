import React from "react"
import styled from "styled-components"
import { standardWrapper, headlineOne, colors } from "../Utilities"

const PostMetaStyled = styled.section`
  .wrapper {
    ${standardWrapper};
    max-width: 90rem;
  }

  .metaDate {
    width: 100%;
    text-align: center;
  }

  .title {
    width: 100%;
    text-align: center;

    h1 {
      ${headlineOne};
      color: ${colors.colorPrimary};
    }
  }
`

const PostMeta = ({ title, date }) => {
  return (
    <PostMetaStyled>
      <div className="wrapper">
        <div className="metaDate">
          <p>{date}</p>
        </div>
        <div className="title">
          <h1>{title}</h1>
        </div>
      </div>
    </PostMetaStyled>
  )
}

export default PostMeta
