import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroWithBackground from "../components/HeroWithBackground"
import PostMeta from "../components/PostMeta"
import WordPressWysiwyg from "../components/WordPressWysiwyg"
import MorePosts from "../components/MorePosts"

import { standardWrapper } from "../Utilities"

const StyledArticle = styled.article`
  ${standardWrapper}
  margin-top: 5rem;
`

const Post = props => {
  const location = props.location.pathname
  const postTitle = props.data.post.title
  const postDate = props.data.post.date
  const heroImgFluid =
    props.data.post.acf &&
    props.data.post.acf._ikag_post_hero_image &&
    props.data.post.acf._ikag_post_hero_image.localFile &&
    props.data.post.acf._ikag_post_hero_image.localFile.childImageSharp &&
    props.data.post.acf._ikag_post_hero_image.localFile.childImageSharp.fluid
  const heroImgAlt =
    props.data.post.acf &&
    props.data.post.acf._ikag_post_hero_image &&
    props.data.post.acf._ikag_post_hero_image.alt_text
  const content = props.data.post.acf._ikag_post_main_content
  const {
    morePosts: { edges: morePosts },
  } = props.data
  return (
    <Layout location={location}>
      <SEO title={`post`} location={props.location.pathname} />
      {heroImgFluid && (
        <HeroWithBackground
          fluid={heroImgFluid}
          heroAlt={heroImgAlt}
          texture={"wood"}
        />
      )}
      <StyledArticle className="postWrapper">
        <PostMeta title={postTitle} date={postDate} />
        <WordPressWysiwyg content={content} />
      </StyledArticle>
      <MorePosts current={props.data.post.wordpress_id} posts={morePosts} />
    </Layout>
  )
}

export const postPageQuery = graphql`
  query postPage($id: Int!) {
    post: wordpressPost(wordpress_id: { eq: $id }) {
      title
      wordpress_id
      date(formatString: "MMMM D, Y")
      acf {
        _ikag_post_hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        _ikag_post_main_content
      }
    }

    morePosts: allWordpressPost(sort: { fields: date, order: ASC }) {
      edges {
        node {
          title
          wordpress_id
          id
          slug
          acf {
            _ikag_post_hero_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Post
