import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import {
  headlineThree,
  colors,
  standardWrapper,
  headlineOne,
} from "../Utilities"

const MorePostsSection = styled.section`
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 30%,
    rgba(157, 201, 228, 0.3) 30%,
    rgba(157, 201, 228, 0.3) 100%
  );

  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h2 {
      ${headlineOne};
      color: ${colors.colorPrimary};
    }
  }

  .moreList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`

const MorePostsItem = styled(Link)`
  width: calc(50% - 4rem);
  margin: 2rem;

  h3 {
    ${headlineThree};
    color: ${colors.colorPrimary};
  }
`

const shuffle = a => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

const MorePosts = ({ current, posts }) => {
  const morePostsDisplay = posts.filter((post, index) => {
    if (post.node.wordpress_id !== current) {
      return post
    }
  })
  const shuffledPosts = shuffle(morePostsDisplay)
  const displayTwo = [shuffledPosts[0], shuffledPosts[1]]

  return (
    <MorePostsSection>
      <div className="wrapper">
        <div className="title">
          <h2>More Posts</h2>
        </div>
        <div className="moreList">
          {displayTwo.map((post, index) => {
            const imageFluid =
              post.node.acf &&
              post.node.acf._ikag_post_hero_image &&
              post.node.acf._ikag_post_hero_image.localFile &&
              post.node.acf._ikag_post_hero_image.localFile.childImageSharp
                .fluid
            const imageAlt =
              post.node.acf._ikag_post_hero_image &&
              post.node.acf._ikag_post_hero_image.alt_text
                ? post.node.acf._ikag_post_hero_image.alt_text
                : "I Know A Guy Renovations."
            return (
              <MorePostsItem to={`/blog/${post.node.slug}`} key={index}>
                <div>
                  {imageFluid && <Img fluid={imageFluid} alt={imageAlt} />}
                </div>
                <div>
                  <h3>{post.node.title}</h3>
                </div>
              </MorePostsItem>
            )
          })}
        </div>
      </div>
    </MorePostsSection>
  )
}

export default MorePosts
